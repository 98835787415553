import React, { useState, useEffect, useCallback } from "react";
import "../css/EOI.css";
import { supabase } from "../supabaseClient"; // Import Supabase client

function EOI({ setShowPopup }) {
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [userIP, setUserIP] = useState(null); // Store user's IP address
  const [userAgent, setUserAgent] = useState(""); // Store user's user-agent

  const closePopup = useCallback(() => {
    setIsVisible(false); // Trigger fade-out animation
    setTimeout(() => setShowPopup(false), 300); // Wait for fade-out effect
  }, [setShowPopup]);

  useEffect(() => {
    const initializePopup = async () => {
      setIsVisible(true);
      const background = document.getElementById("blur-content");
      if (background) background.classList.add("blur-background");

      try {
        // Attempt to fetch the user's IP address
        console.log("Fetching user's IP address...");
        let ip = null; // Default to null if IP fetching fails
        try {
          const response = await fetch("https://api.ipify.org?format=json");
          const data = await response.json();
          ip = data.ip || null; // Fallback to null if data is missing
          console.log("User IP fetched:", ip);
        } catch (err) {
          console.warn("Failed to fetch IP address:", err.message);
        }

        // Set the user-agent string (fallback to empty string if not available)
        const agent = navigator.userAgent || "";
        setUserAgent(agent);

        // Check if the IP already exists in Supabase
        if (ip) {
          console.log("Checking if IP exists in Supabase...");
          const { data, error } = await supabase.from("EOI").select("*").eq("ip", ip);

          if (error) {
            console.error("Error fetching EOI entries:", error.message);
          } else if (data && data.length > 0) {
            console.log("IP already exists in the database. Closing popup.");
            setShowPopup(false);
          } else {
            console.log("IP not found in the database. Proceeding.");
          }
        } else {
          console.warn("IP is null, skipping Supabase IP check.");
        }
      } catch (err) {
        console.error("Error initializing popup:", err.message);
      }
    };

    initializePopup();

    // Prevent background scrolling
    document.body.style.overflow = "hidden";

    // Add event listener for Escape key to close the popup
    const handleKeyDown = (e) => {
      if (e.key === "Escape") closePopup();
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      const background = document.getElementById("blur-content");
      if (background) background.classList.remove("blur-background");
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closePopup, setShowPopup]);

  const handleOverlayClick = (e) => {
    if (e.target.className.includes("eoi-overlay")) {
      closePopup();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Field changed: ${name} = ${value}`); // Debug log
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form refresh
    console.log("Form submission started...");

    try {
      // Log the data to be submitted
      console.log("Submitting data to Supabase:", {
        name: formData.name,
        email: formData.email,
        ip: userIP,
        userAgent,
      });

      // Insert data into Supabase
      const { error } = await supabase.from("EOI").insert([
        {
          name: formData.name,
          email: formData.email,
          ip: userIP || null, // Insert null if IP is unavailable
          user_agent: userAgent || "", // Insert empty string if user-agent is unavailable
          geolocation: "", // Placeholder for geolocation
          created_at: new Date().toISOString(),
        },
      ]);

      if (error) {
        console.error("Error saving EOI:", error.message);
        alert("Failed to submit. Please try again.");
      } else {
        console.log("Form submitted successfully!");
        alert("Expression of interest submitted successfully!");
        closePopup();
      }
    } catch (err) {
      console.error("Unexpected error during form submission:", err.message);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div
      className={`eoi-overlay ${isVisible ? "fade-in" : "fade-out"}`}
      onClick={handleOverlayClick}
      role="dialog"
      aria-modal="true"
      aria-labelledby="eoi-title"
    >
      <div className="eoi-popup">
        {/* X Button */}
        <button
          className="eoi-close-button"
          onClick={closePopup}
          aria-label="Close"
        >
          &times;
        </button>

        <h2 id="eoi-title">Expression of Interest</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <div className="eoi-submit-container">
            <button type="submit" className="eoi-submit-button">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EOI;
