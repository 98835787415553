import '../css/Settings.css';
import React, { useState, useEffect } from 'react';
import ProfileForm from '../components/ProfileForm';
import SubscriptionManagement from '../components/SubscriptionManagement';
import NotificationPreferences from '../components/NotificationPreferences';
import { supabase } from '../supabaseClient';

function Settings() {
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null); // State for subscription data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        console.log("Fetched user:", user); // Log user data

        if (user) {
          const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('username, phone')
            .eq('user_id', user.id)
            .single();
          
          if (profileError) throw profileError;

          setUserData({
            id: user.id,
            username: profileData.username,
            phone: profileData.phone,
            email: user.email,
          });

          console.log("User data set in state:", {
            id: user.id,
            username: profileData.username,
            phone: profileData.phone,
            email: user.email,
          });

          // Fetch subscription data
          const { data: subscriptionData, error: subscriptionError } = await supabase
            .from('Subscriptions')
            .select('subscription_id, status, plan_id')
            .eq('user_id', user.id)
            .single();

          if (subscriptionError) {
            console.warn("No subscription found for this user.");
            setSubscriptionData(null); // Set to null if there's no subscription data
          } else {
            setSubscriptionData(subscriptionData);
            console.log("Fetched subscription data:", subscriptionData); // Log subscription data
          }
        }
      } catch (error) {
        console.error('Error fetching user or subscription data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="settings-page">
      {userData && (
        <>
          <div className="settings-section">
            <ProfileForm userId={userData.id} currentUsername={userData.username} currentEmail={userData.email} currentPhone={userData.phone} />
          </div>
          <div className="settings-section">
            <SubscriptionManagement userData={userData} subscriptionData={subscriptionData} />
          </div>
          <div className="settings-section notification-preferences">
            <NotificationPreferences />
          </div>
        </>
      )}
    </div>
  );
}

export default Settings;
