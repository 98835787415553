import React, { useState } from 'react';
import { supabase } from '../supabaseClient';


function NotificationPreferences() {
  const [smsOptIn, setSmsOptIn] = useState(false);

  const handleToggleSmsNotifications = async () => {
    // Assuming you have a custom API endpoint or way to store SMS preference
    const { error } = await supabase.from('profiles').update({ sms_notifications: smsOptIn });

    if (error) {
      alert('Failed to update notification preferences: ' + error.message);
    } else {
      alert('Notification preferences updated successfully');
      setSmsOptIn(!smsOptIn);
    }
  };

  return (
    <div>
      <h3>Notification Preferences</h3>
      <label>
        <input
          type="checkbox"
          checked={smsOptIn}
          onChange={handleToggleSmsNotifications}
        />
        Opt-in to SMS Bet Notifications
      </label>
    </div>
  );
}

export default NotificationPreferences;