import React from 'react';
import Login from '../components/Login';

function LoginPage() {
  return (
    <div className="container">
      <Login />
    </div>
  );
}

export default LoginPage;