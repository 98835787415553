import React from 'react';
import Signup from '../components/Signup'

function SignupPage() {
  return (
    <div className="container">
        <Signup />
    </div>
  );
}

export default SignupPage;