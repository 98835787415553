import '../css/Navbar.css';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Import the AuthContext hook
import { supabase } from '../supabaseClient'; // Import supabase client
import { Link as ScrollLink } from 'react-scroll';


function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { user, setUser } = useAuth(); // Get user and setUser from context
  const navigate = useNavigate();
  const menuRef = useRef(null); // Ref to track the menu

  // Set isLoggedIn based on user context
  const isLoggedIn = Boolean(user);

  useEffect(() => {
    // Listen for auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
    });

    // Cleanup the listener on unmount
    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, [setUser]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuClick = () => {
    setIsMenuOpen(false); // Close the menu on button click
  };


  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error.message);
    } else {
      setUser(null); // Clear user from context on logout
      setIsUserMenuOpen(false); // Close the user menu
      localStorage.removeItem("token"); // Clear the token from localStorage
      navigate('/login'); // Redirect to login page
    }
  };


  return (
    <header>
      <div className="navbar">
        {/* Hamburger Menu Icon */}
        <div className="menu-icon" onClick={toggleMenu}>
          ☰
        </div>
  
        {/* Centered Logo */}
        <div className="logo">
          <Link to="/">
            <img
              src="/images/logo.png"
              alt="Moolah logo"
              className="logo-full"
            />
            <img
              src="/images/icon.jpg"
              alt="Moolah icon"
              className="logo-icon"
            />
          </Link>
        </div>
  
        {/* Auth Buttons for Desktop */}
        <div className="auth-buttons">
          {!isLoggedIn ? (
            <>
              <Link to="/login" className="login-btn desktop-only">Login</Link> {/* Only visible on desktop */}
              <Link to="/signup" className="signup-btn">Sign Up</Link>
            </>
          ) : (
            <>
              <div className="user-icon" onClick={toggleUserMenu}>👤</div>
              {isUserMenuOpen && (
                <ul className="user-dropdown">
                  <li><Link to="/settings">Settings</Link></li>
                  <li><button onClick={handleSignOut} className="sign-out-btn">Sign Out</button></li>
                </ul>
              )}
            </>
          )}
        </div>
  
        {/* Full-Screen Nav Links */}
        <ul
          className={`nav-links ${isMenuOpen ? 'active' : ''}`}
          ref={menuRef} // Attach the ref to the menu
        >
          {!user ? (
            <>
              <li><Link to="/?section=about" onClick={handleMenuClick}>About</Link></li>
              <li><Link to="/?section=features" onClick={handleMenuClick}>Features</Link></li>
              <li><Link to="/?section=pricing" onClick={handleMenuClick}>Pricing</Link></li>
              <li><Link to="/results" onClick={handleMenuClick}>Results</Link></li>
              <li className="mobile-only"><Link to="/login" className="login-btn" onClick={handleMenuClick}>Login</Link></li>
            </>
          ) : (
            <>
              <li><Link to="/home" onClick={handleMenuClick}>Picks</Link></li>
              <li><Link to="/user-results" onClick={handleMenuClick}>My Results</Link></li>
              <li><Link to="/performance" onClick={handleMenuClick}>Performance</Link></li>
            </>
          )}
        </ul>
      </div>
    </header>
  );
}

export default Navbar;