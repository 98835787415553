import React, { useEffect, useState } from 'react';
import ResultsTable from '../components/ResultsTable';
import Spinner from '../components/Spinner';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function UserResultsHistory() {
    const [userResults, setUserResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserResults = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${BACKEND_URL}/api/results/user-results`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user results');
                }

                const data = await response.json();
                setUserResults(data.data || []);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchUserResults();
    }, []);

    if (loading) return <Spinner/>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <ResultsTable resultsData={userResults} />
        </div>
    );
}
export default UserResultsHistory;