import React from 'react';

function Features() {
    // Define the features data
    const featuresData = [
      {
        title: "AFL",
        imageSrc: "/images/AFL/afl.png",
        altText: "Football",
        description: "Predictions for the following:",
        items: [
          "Margin",
          "Match winner",
          "Player Disposals",
          "Player Kicks",
          "Player Tackles",
          "Player Goals",
          "Player Fantasy"
        ],
      },
      {
        title: "NRL",
        imageSrc: "/images/NRL/NRL.png",
        altText: "NRL",
        description: "Predictions for the following:",
        items: [
          "In-depth team analysis",
          "Player performance trends",
          "Game predictions",
          "Betting odds"
        ],
      }
    ];
  
    return (
      <section id="features" className="features-section">
        {featuresData.map((feature, index) => (
          <div key={index} className="feature-card">
            <img src={feature.imageSrc} alt={feature.altText} className="feature-image" />
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
            <ul>
              {feature.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
            {/* <button className="feature-button">Learn More</button>  remove for now possibly link to results later */}
          </div>
        ))}
      </section>
    );
  }
  
  export default Features;