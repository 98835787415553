import React, { useEffect, useState } from 'react';
import '../css/Tile.css';
import Graph from './Graph';

const Tile = ({ data, onExpand, index, expandedIndex }) => {
    const [isSelected, setIsSelected] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [alignment, setAlignment] = useState('middle');

    // Check if this tile is the one currently expanded
    const isExpanded = expandedIndex === index;

    // Ref to the tile element to calculate its position on screen
    const tileRef = React.useRef();

    const toggleFlip = (event) => {
        if (event) event.stopPropagation(); // Prevent triggering the expand event
        setIsFlipped((prev) => !prev);
    };

    // Toggle selected state for the button
    const handleButtonClick = (event) => {
        event.stopPropagation(); // Prevent triggering the expand event
        setIsSelected((prevState) => !prevState);
    };

    // Automatically unflip when tile is unexpanded
    useEffect(() => {
        if (!isExpanded) {
            setIsFlipped(false);
        }
    }, [isExpanded]);

    // Determine alignment for the tile based on its position relative to the viewport
    useEffect(() => {
        const updateAlignment = () => {
            const tileElement = tileRef.current;
            if (tileElement) {
                const rect = tileElement.getBoundingClientRect();
                const screenWidth = window.innerWidth;

                if (rect.left < screenWidth * 0.05) {
                    setAlignment("left-align");
                } else if (rect.right > screenWidth * 0.95) {
                    setAlignment("right-align");
                } else {
                    setAlignment("middle");
                }
            }
        };

        // Update alignment on initial render and on window resize
        window.addEventListener('resize', updateAlignment);
        updateAlignment();

        return () => window.removeEventListener('resize', updateAlignment);
    }, [expandedIndex]);

    if (!data) return null;
    console.log('PlayerID:', data.PlayerID, 'GameDate:', data.GameDate);


    return (
        <div
            ref={tileRef}
            className={`tile ${isExpanded ? `expanded ${alignment}` : ''} ${isFlipped ? 'flipped' : ''}`}
            onClick={onExpand}
        >
            {!isFlipped ? (
                // Front ****************
                <>
                    <div className="tile-header">
                        <button
                            onClick={handleButtonClick}
                            className={`tile-button ${isSelected ? 'selected' : ''}`}
                        >
                            {isSelected ? '\u00D7' : '+'}
                        </button>
                        <h3 className="tile-gameInfo">
                            {data?.AwayTeam?.replace(/_/g, ' ') || 'N/A'} vs {data?.HomeTeam?.replace(/_/g, ' ') || 'N/A'}
                        </h3>
                    </div>
                    <div className="tile-title">
                        {data?.AwayTeam?.replace(/_/g, ' ') || 'N/A'} vs {data?.HomeTeam?.replace(/_/g, ' ') || 'N/A'}
                    </div>
                    <div className={`tile-content ${isExpanded ? 'expanded' : ''}`}>
                        <p>{data.PlayerName}</p>
                        <p>Line: {data.StatLine} {data.OverUnder}</p>
                        <p>
                            <span style={{color:'#a0e4b0', fontWeight: 'bold'}}>Mooolah </span>Appraisal: {`${(1 / (parseFloat(data.Model_probability) / 100)).toFixed(2)}`}
                        </p>
                        <p>{data.Bookie}: {data.Odds}</p>

                        {/* Extra content for expanded tile */}
                        {isExpanded && (
                            <div className="tile-extra">
                                <button
                                    className="flip-toggle"
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent triggering expand
                                        toggleFlip();
                                    }}
                                >
                                    {isFlipped ? '<' : '>'} {/* Change icon dynamically */}
                                </button>
                                <p><strong>Additional Stats:</strong></p>
                                <ul>
                                    <li>Game date: {data.GameDate}</li>
                                    <li>Model: {data.Model_probability}</li>
                                    <li>Implied: {data.Implied_probability}</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                /// Back ****************
                <div className="tile-back">
                    <button
                        className="flip-toggle"
                        onClick={(event) => {
                            event.stopPropagation(); // Prevent triggering expand
                            toggleFlip();
                        }}
                    >
                        {isFlipped ? '<' : '>'} {/* Change icon dynamically */}
                    </button>
                    <h3>Player Performance (Last 5 Games)</h3>
                    <Graph
                        playerId={data.PlayerID}
                        gameDate={data.GameDate}
                        betStatType={data.BetStatType}
                        benchmarkValue={data.StatLine}
                    />
                </div>

            )}
        </div>
    );
};

export default Tile;
