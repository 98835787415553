import React from 'react';
import Footer from "../components/Footer";
import '../css/PrivacyPage.css'

function PrivacyPage() {
    return (
      <div className="privacy-page-container">
        <div className="privacy-page">
          <h2>Privacy Policy</h2>
  
          <h3>We Respect Your Privacy</h3>
          <p>
            Mooolah Pty Ltd respects your right to privacy and is committed to safeguarding the personal information of 
            our customers and website visitors. We adhere to the Australian Privacy Principles contained in the Privacy Act 
            1988 (Cth). This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information.
          </p>
  
          <h3>Collection of Personal Information</h3>
          <p>
            Mooolah Pty Ltd may collect and store personal information provided directly by you, entered on our website, or 
            shared with us through other means.
          </p>
          <p>
            You may provide information such as your name, email address, and phone number to enable us to provide updates, 
            marketing materials, or process service requests. We may also collect additional information when you:
          </p>
          <ul>
            <li>Provide feedback or complete surveys.</li>
            <li>Participate in promotions or competitions.</li>
            <li>Provide payment details for subscriptions or services.</li>
            <li>Communicate with our customer support team.</li>
          </ul>
          <p>Additionally, we may collect other information you provide while interacting with our platform.</p>
  
          <h3>How We Collect Your Personal Information</h3>
          <p>
            Mooolah Pty Ltd collects personal information in various ways, including:
          </p>
          <ul>
            <li>Direct interactions with you (e.g., filling out forms on our website).</li>
            <li>When you purchase a subscription, access services, or interact with customer support.</li>
            <li>When you browse or interact with our website.</li>
            <li>From third-party sources (e.g., service providers or partners).</li>
          </ul>
          <p>We ensure any third-party data is protected and handled per this Privacy Policy.</p>
  
          <h3>Use of Your Personal Information</h3>
          <p>
            The personal information collected may be used to:
          </p>
          <ul>
            <li>Provide updates, marketing communications, and service information.</li>
            <li>Improve our services and better understand your needs.</li>
            <li>Make you aware of new products, services, or features relevant to you.</li>
            <li>Process payments and deliver the services you have requested.</li>
          </ul>
          <p>
            We may contact you via email, SMS, or other communication methods to keep you informed about your account or our services.
          </p>
  
          <h3>Disclosure of Your Personal Information</h3>
          <p>
            We may disclose your personal information to:
          </p>
          <ul>
            <li>Employees, contractors, service providers, or partners as necessary to deliver our services.</li>
            <li>Comply with legal requirements such as court orders, regulations, or law enforcement requests.</li>
            <li>Protect our intellectual property, legal rights, and the safety of our users.</li>
          </ul>
          <p>
            In some cases, personal information may be transferred to and stored in countries outside of Australia, where we work 
            with global service providers.
          </p>
          <p>
            If there is a change of ownership or sale of Mooolah Pty Ltd, personal information may be transferred to the new owner 
            in accordance with this Policy.
          </p>
  
          <h3>Security of Your Personal Information</h3>
          <p>
            We are committed to protecting the information you provide. Our safeguards include physical, electronic, and 
            administrative measures to secure your data against unauthorized access, misuse, loss, or disclosure.
          </p>
          <p>
            Please note, however, that no method of transmission or storage is entirely secure, and we cannot guarantee absolute 
            security of your information.
          </p>
          
          <h3>Access to Your Personal Information</h3>
            <p>
            You may request details of personal information that we hold about you in accordance with the provisions of the Privacy Act 
            1988 (Cth). A small administrative fee may be payable for the provision of information. If you would like a copy of the 
            information we hold about you or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant, 
            or misleading, please email us at <a href="mailto:support@mooolah.com.au" className="privacy-link">support@mooolah.com.au</a>.
            </p>
            <p>
            We reserve the right to refuse to provide you with information that we hold about you in certain circumstances set out in the 
            Privacy Act.
            </p>
        
            <h3>Complaints About Privacy</h3>
            <p>
            If you have any complaints about our privacy practices, please feel free to send in details of your complaints to 
            <a href="mailto:support@mooolah.com.au" className="privacy-link">support@mooolah.com.au</a>. We take complaints very 
            seriously and will respond shortly after receiving notice of your complaint.
            </p>

            <h3>Changes to Privacy Policy</h3>
            <p>
            Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, at our sole 
            discretion, and all modifications will be effective immediately upon our posting of the modifications on our website or 
            notice board. Please check back from time to time to review our Privacy Policy.
            </p>

            <h3>Website</h3>

            <h4>When You Visit Our Website</h4>
            <p>
            When you come to our website (www.mooolah.com.au), we may collect certain information such as browser type, operating 
            system, website visited immediately before coming to our site, etc. This information is used in an aggregated manner to 
            analyze how people use our site, so we can improve our service.
            </p>

            <h4>Cookies</h4>
            <p>
            We may from time to time use cookies on our website. Cookies are very small files which a website uses to identify you 
            when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that 
            access or damage your computer. Most web browsers automatically accept cookies, but you can choose to reject cookies 
            by changing your browser settings. However, this may prevent you from taking full advantage of our website.
            </p>
            <p>
            Our website may from time to time use cookies to analyze website traffic and help us provide a better website visitor 
            experience. In addition, cookies may be used to serve relevant ads to website visitors through third-party services such 
            as Google Adwords. These ads may appear on this website or other websites you visit.
            </p>

            <h4>Third-Party Sites</h4>
            <p>
            Our site may from time to time have links to other websites not owned or controlled by us. These links are meant for your 
            convenience only. Links to third-party websites do not constitute sponsorship or endorsement or approval of these websites. 
            Please be aware that Mooolah Pty Ltd is not responsible for the privacy practices of other such websites. We encourage our 
            users to be aware when they leave our website to read the privacy statements of each and every website that collects 
            personally identifiable information.
            </p>


                                
     
        </div>
        <div>
            <Footer />
        </div>
      </div>
    );
  }
  
  export default PrivacyPage;