import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SubscriptionManagement({ userData, subscriptionData }) {  
  const navigate = useNavigate();

  const handleCancelSubscription = async () => {
    try {
      if (!userData || !subscriptionData) {
        console.error("User or subscription data is not available");
        return;
      }

      const { id: userId } = userData;
      const { subscription_id: subscriptionId } = subscriptionData; // Access subscription_id

      // Make API request to cancel the subscription
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stripe/cancel-subscription`, {
        userId,
        subscriptionId,
      });

      if (response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      alert('Failed to cancel subscription');
    }
  };

  const handleChangeSubscription = () => {
    if (!subscriptionData) {
      console.error("Subscription data is missing");
      return;
    }
    // Redirect to PlansPage with query params to indicate a change action
    navigate(`/plans?change=true&subscriptionId=${subscriptionData.subscription_id}`);
  };
  
// remove Change subscription button for now 
  return (
    <div>
      <h3>Subscription Management</h3>
      <button onClick={handleCancelSubscription}>Cancel Subscription</button>
      {/* <button onClick={handleChangeSubscription}>Change Subscription</button> */}
    </div>
  );
}

export default SubscriptionManagement;