// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Spinner Container */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    background-color: #111; /* Optional background for overlay */
  }
  
  /* Spinner Animation */
  .spinner {
    width: 80px;
    height: 80px;
    border: 12px solid #737373; /* Light gray background of the spinner */
    border-top: 12px solid #a0e4b0; /* Green spinning part */
    border-radius: 50%; /* Make it circular */
    animation: spin 1s linear infinite; /* Spin animation */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/Spinner.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,uBAAuB;IACtC,sBAAsB,EAAE,oCAAoC;EAC9D;;EAEA,sBAAsB;EACtB;IACE,WAAW;IACX,YAAY;IACZ,0BAA0B,EAAE,yCAAyC;IACrE,8BAA8B,EAAE,wBAAwB;IACxD,kBAAkB,EAAE,qBAAqB;IACzC,kCAAkC,EAAE,mBAAmB;EACzD;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["/* Spinner Container */\n.spinner-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* Full screen height */\n    background-color: #111; /* Optional background for overlay */\n  }\n  \n  /* Spinner Animation */\n  .spinner {\n    width: 80px;\n    height: 80px;\n    border: 12px solid #737373; /* Light gray background of the spinner */\n    border-top: 12px solid #a0e4b0; /* Green spinning part */\n    border-radius: 50%; /* Make it circular */\n    animation: spin 1s linear infinite; /* Spin animation */\n  }\n  \n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
