import React, { useEffect, useState } from 'react';
import ResultsTable from '../components/ResultsTable';
import Spinner from '../components/Spinner';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function ResultsLandingPage() {
    const [resultsData, setResultsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchResults = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${BACKEND_URL}/api/results/landing`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Ensure token is passed
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch results data');
                }

                const jsonResponse = await response.json();
                if (jsonResponse.success) {
                    setResultsData(jsonResponse.data); // Extract and set the `data` array
                } else {
                    throw new Error('API returned success: false');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
    }, []);

    if (loading) return <Spinner/>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <ResultsTable resultsData={resultsData} />
        </div>
    );
}

export default ResultsLandingPage;