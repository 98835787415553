import '../css/form-styles.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { useAuth } from '../context/AuthContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { user, setUser } = useAuth(); // Use setUser from context

  const handleLogin = async (e) => {
    e.preventDefault();
  
    // Attempt to sign in
    const { error, data } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
  
    if (error) {
      console.error("Login failed:", error.message);
      alert("Login failed. Please try again.");
      return;
    }
  
    console.log("Login successful, session data:", data); //TODO: remove
  
    // Fetch the session data to confirm login
    const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
  
    if (sessionError) {
      console.error("Failed to fetch session:", sessionError.message);
      return;
    }
  
    console.log("Fetched session after login:", sessionData);
  
    const userId = sessionData.session?.user?.id;
    const accessToken = sessionData.session?.access_token;
  
    if (!userId || !accessToken) {
      console.error("User ID or Access Token not found in session data.");
      return;
    }
  
    // Save the access token and userid in localStorage
    localStorage.setItem("token", accessToken);
    localStorage.setItem("userId", userId);
  
    // Update the user in the context
    setUser(sessionData.session.user);
  
    // Check if the user has an active subscription
    const { data: subscriptionData, error: subscriptionError } = await supabase
      .from("Subscriptions")
      .select("status")
      .eq("user_id", userId)
      .eq("status", "active")
      .maybeSingle();
  
    if (subscriptionError) {
      console.error("Error fetching subscription:", subscriptionError.message);
      alert("Failed to check subscription status.");
      return;
    }
  
    if (subscriptionData) {
      console.log("Active subscription found, redirecting to /home");
      navigate("/home"); // Redirect to home if user has an active subscription
    } else {
      console.log("No active subscription found, redirecting to /plans");
      navigate("/plans"); // Redirect to plans if no active subscription
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      alert("Please enter your email address to reset your password.");
      return;
    }

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`, // Replace with your reset password URL
    });

    if (error) {
      console.error("Password reset failed:", error.message);
      alert("Failed to send password reset email.");
    } else {
      alert("Password reset email sent. Please check your inbox.");
    }
  };

  return (
    <div className="auth-form">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
      <p 
        onClick={handlePasswordReset} 
        style={{ textDecoration: 'underline', cursor: 'pointer', color: '#007bff', marginTop: '10px' }}
      >
        I forgot my password
      </p>
    </div>
  );
}

export default Login;