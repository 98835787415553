import React, { useEffect } from 'react';
import axios from 'axios';
import { supabase } from '../supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/PlansPage.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const plans = [
  { name: 'Weekly', priceId: 'price_1Q7xC2B39d9MJKdGNhVW5B2T', price: '$9.99/month' },
  { name: 'Monthly', priceId: 'price_1Q7xPJB39d9MJKdGKouKLtNP', price: '$19.99/month' },
  { name: 'Yearly', priceId: 'price_1Q7xPVB39d9MJKdGGQMdFK5j', price: '$29.99/month' },
];

function PlansPage() {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if the user is in "change mode" by checking the URL parameters
  const params = new URLSearchParams(location.search);
  const isChangeMode = params.get("change") === "true";
  const subscriptionId = params.get("subscriptionId");

  useEffect(() => {
    // Check the current session when the page loads
    const checkSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error("Error fetching session:", error.message);
      } else if (!session) {
        console.log("No session found on page load.");
        alert("Session expired, please log in again.");
        navigate('/login');
      } else {
        console.log("Current Supabase session:", session);
      }
    };

    checkSession();
  }, [navigate]);

  const handleSubscribe = async (priceId) => {
    try {
      console.log("Checking if user is logged in...");
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();

      if (sessionError || !session) {
        console.error("No active session found:", sessionError?.message);
        alert("Please log in to subscribe to a plan.");
        navigate('/login');
        return;
      }

      console.log("User is logged in:", session.user);

      if (isChangeMode && subscriptionId) {
        // Change the existing subscription plan
        const response = await axios.post(`${BACKEND_URL}/stripe/change-subscription`, {
          subscriptionId,
          newPlanId: priceId,
        });

        if (response.data.message) {
          alert(response.data.message);
        } else {
          alert("Subscription changed successfully");
        }
        // Redirect back to settings or another relevant page
        navigate('/settings');
      } else {
        // Start a new subscription checkout session
        const response = await axios.post(`${BACKEND_URL}/stripe/create-checkout-session`, {
          priceId,
          userId: session.user.id,
        });

        console.log("Checkout session created:", response.data.url);
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error("Error in handleSubscribe:", error);
      alert("Failed to create or change subscription");
    }
  };

  return (
    <div className="plans-page">
      <h2>{isChangeMode ? "Change Your Plan" : "Select a Plan"}</h2>
      <div className="plans-container">
        {plans.map((plan) => (
          <div key={plan.priceId} className="plan-card">
            <h3 className="plan-title">{plan.name}</h3>
            <p className="plan-price">{plan.price}</p>
            <button
              className="subscribe-btn"
              onClick={() => handleSubscribe(plan.priceId)}
            >
              {isChangeMode ? "Switch to this Plan" : "Subscribe"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlansPage;