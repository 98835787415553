import '../css/Features-Pricing.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';

function Pricing() {

  const navigate = useNavigate(); // Initialize navigate function

  const handleGetStartedClick = () => {
    navigate('/signup'); // Navigate to the signup page
  };


    const pricingPlans = [
      {
        title: "Weekly",
        price: "$17.49/wk",
        description: "Perfect for casual bettors looking for basic insights.",
        features: [
          "Full access to our AFL predictions",
          "Bet tracking tools and risk profile calculators",
          "Community support"
        ],
      },
      {
        title: "Monthly",
        price: "$59.99/mo",
        description: "Advanced stats and insights for serious bettors.",
        features: [
          "Full access to our AFL predictions",
          "Bet tracking tools and risk profile calculators",
          "Access to our multibet creator algorithms",
          "Community support"
        ],
      },
      {
        title: "Yearly",
        price: "$599.99/yr",
        description: "Comprehensive insights for professional bettors.",
        features: [
          "Full access to our AFL predictions",
          "Bet tracking tools and risk profile calculators",
          "Access to our multibet creator algorithms",
          "Community support"
        ],
        recommended: true // Optional: flag for recommended plans
      }
    ];
  
    return (
      <section id="pricing" className="pricing-section">
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            className={`pricing-card ${plan.recommended ? "recommended" : ""}`}
          >
            <h3>{plan.title}</h3>
            <strong>{plan.price}</strong>
            <p>{plan.description}</p>
            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <button className="pricing-button" onClick={handleGetStartedClick} >SIGN UP</button>
          </div>
        ))}
      </section>
    );
  }
  
  export default Pricing;