import React, { useEffect, useState } from 'react';
import FilterMenu from '../components/FilterMenu';
import Tile from '../components/Tile';
import '../css/Home.css';
import Spinner from '../components/Spinner';
import GameTile from '../components/GameTile';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function HomePage() {
    const [tilesData, setTilesData] = useState([]);
    const [displayCount, setDisplayCount] = useState(0); // Initial count based on visible columns
    const [expandedTileIndex, setExpandedTileIndex] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const [selectedSubmenu, setSelectedSubmenu] = useState('gamePicks'); // 'gamePicks' or 'playerPicks'
    const [filterMenuVisible, setFilterMenuVisible] = useState(false); // For toggling the FilterMenu

    // State for filters
    const [selectedGame, setSelectedGame] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSport, setSelectedSport] = useState('');
    const [minAdvantage, setMinAdvantage] = useState('');
    const [maxAdvantage, setMaxAdvantage] = useState('');
    const [minOdds, setMinOdds] = useState('');
    const [maxOdds, setMaxOdds] = useState('');
    const [games, setGames] = useState([]);

    // Function to calculate how many tiles fit in one row
    const calculateVisibleTiles = () => {
        const container = document.querySelector('.tiles-container');
        if (container) {
            const containerWidth = container.offsetWidth;
            const minTileWidth = 276; // 250px tile width + 20px gap (adjust gap if needed)
            // Use a tolerance buffer of 10px to avoid miscalculations on the edge of rounding
            const tilesPerRow = Math.floor((containerWidth + 10) / minTileWidth); 
            const rowsToShow = 2; // Show 2 rows initially (or adjust this to your preference)
            return tilesPerRow * rowsToShow;
        }
        return 5; // Fallback value
    };

    // Fetch relevant games on load
    useEffect(() => {
        fetch(`${BACKEND_URL}/api/games/relevant-games`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setGames(data || []);
            })
            .catch((error) => console.error('Error fetching relevant games:', error));
    }, []);


    useEffect(() => {
        console.log("Fetching games for the filter menu...");
        // Fetch games data for the dropdown menu
        fetch(`${BACKEND_URL}/betting/games`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok");
                return response.json();
            })
            .then((data) => setGames(data))
            .catch((error) => console.error("Error fetching games:", error));
    }, []);


    const fetchPlayerPicksData = (filters = {}) => {
        console.log('Fetching Player Picks Data with filters:', filters);
    
        const {
            game = '',
            date = '',
            minAdvantage = '',
            maxAdvantage = '',
            minOdds = '',
            maxOdds = ''
        } = filters;
    
        let homeTeamID = '';
        let awayTeamID = '';
    
        if (game && game.includes(' vs ')) {
            [homeTeamID, awayTeamID] = game.split(' vs ');
        } else if (game) {
            console.error(`Invalid game format: ${game}`);
        }
    
        const params = new URLSearchParams({
            home_team_id: homeTeamID || '',
            away_team_id: awayTeamID || '',
            selected_date: date || '',
            min_advantage: minAdvantage ? parseFloat(minAdvantage) : '',
            max_advantage: maxAdvantage ? parseFloat(maxAdvantage) : '',
            min_odds: minOdds ? parseFloat(minOdds) : '',
            max_odds: maxOdds ? parseFloat(maxOdds) : '',
        });
    
        console.log('Player Picks Params:', params.toString());
    
        const endpoint = '/betting/player-best-odds';
        console.log('Fetching from Player Picks Endpoint:', `${BACKEND_URL}${endpoint}`);
    
        setLoading(true);
        fetch(`${BACKEND_URL}${endpoint}?${params.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(async (response) => {
                if (!response.ok) {
                    const text = await response.text();
                    console.error('Player Picks Response Error:', text);
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Player Picks Data:', data);
                setTilesData(data || []);
            })
            .catch((error) => console.error('Error fetching Player Picks Data:', error))
            .finally(() => setLoading(false));
    };
    
    const fetchGamePicksData = (filters = {}) => {
        console.log('Fetching Game Picks Data with filters:', filters);
    
        const {
            game = '',
            date = '',
            minOdds = '',
            maxOdds = ''
        } = filters;
    
        let homeTeamID = '';
        let awayTeamID = '';
    
        if (game && game.includes(' vs ')) {
            [homeTeamID, awayTeamID] = game.split(' vs ');
        } else if (game) {
            console.error(`Invalid game format: ${game}`);
        }
    
        const params = new URLSearchParams({
            home_team_id: homeTeamID || '',
            away_team_id: awayTeamID || '',
            selected_date: date || '',
            min_odds: minOdds ? parseFloat(minOdds) : '',
            max_odds: maxOdds ? parseFloat(maxOdds) : '',
        });
    
        console.log('Game Picks Params:', params.toString());
    
        const endpoint = '/betting/ranked-team-bets';
        console.log('Fetching from Game Picks Endpoint:', `${BACKEND_URL}${endpoint}`);
    
        setLoading(true);
        fetch(`${BACKEND_URL}${endpoint}?${params.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(async (response) => {
                if (!response.ok) {
                    const text = await response.text();
                    console.error('Game Picks Response Error:', text);
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Game Picks Data:', data);
                setTilesData(data || []);
            })
            .catch((error) => console.error('Error fetching Game Picks Data:', error))
            .finally(() => setLoading(false));
    };
    
    useEffect(() => {
        if (selectedSubmenu === 'playerPicks') {
            fetchPlayerPicksData();
        } else if (selectedSubmenu === 'gamePicks') {
            fetchGamePicksData();
        }
    }, [selectedSubmenu, selectedGame, selectedDate, minAdvantage, maxAdvantage, minOdds, maxOdds]);



    // Adjust tile display on window resize
    useEffect(() => {
        const calculateVisibleTiles = () => {
            const container = document.querySelector('.tiles-container');
            if (container) {
                const containerWidth = container.offsetWidth;
                const minTileWidth = 276;
                const tilesPerRow = Math.floor((containerWidth + 10) / minTileWidth);
                return tilesPerRow * 2; // Default 2 rows
            }
            return 5;
        };

        const initialTiles = calculateVisibleTiles();
        setDisplayCount(initialTiles);

        const handleResize = () => {
            const visibleTiles = calculateVisibleTiles();
            setDisplayCount((prev) => Math.max(prev, visibleTiles));
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSubmenuChange = (submenu) => {
        setSelectedSubmenu(submenu);
    };

    const toggleFilterMenu = () => {
        setFilterMenuVisible((prev) => !prev);
    };

    return (
        <div className="home-container">
            {/* Submenu Bar */}
            <div className="submenu-bar">
                <div className="submenu-items">
                    <div
                        className={`submenu-item ${selectedSubmenu === 'gamePicks' ? 'active' : ''}`}
                        onClick={() => handleSubmenuChange('gamePicks')}
                    >
                        Game Picks
                    </div>
                    <div
                        className={`submenu-item ${selectedSubmenu === 'playerPicks' ? 'active' : ''}`}
                        onClick={() => handleSubmenuChange('playerPicks')}
                    >
                        Player Picks
                    </div>
                </div>
                <div className="filter-trigger" onClick={toggleFilterMenu}>
                    {filterMenuVisible ? '▲ Filter Options' : '▼ Filter Options'}
                </div>
            </div>

            {/* Filter Menu Dropdown */}
            {filterMenuVisible && (
                <div className="filter-menu-container">
                    <FilterMenu
                        games={games}
                        selectedGame={selectedGame}
                        setSelectedGame={setSelectedGame}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        minAdvantage={minAdvantage}
                        setMinAdvantage={setMinAdvantage}
                        maxAdvantage={maxAdvantage}
                        setMaxAdvantage={setMaxAdvantage}
                        minOdds={minOdds}
                        setMinOdds={setMinOdds}
                        maxOdds={maxOdds}
                        setMaxOdds={setMaxOdds}
                        onSubmitFilters={(filters) => {
                            if (selectedSubmenu === 'playerPicks') {
                                fetchPlayerPicksData(filters);
                            } else {
                                fetchGamePicksData(filters);
                            }
                        }}
                    />
                </div>
            )}

            {loading ? (
                <Spinner />
            ) : (
                <div className="tiles-wrapper">
                    <div className="tiles-container">
                        {tilesData.slice(0, displayCount).map((tileData, index) => {
                            console.log('Tile Data:', tileData); // Ensure the log is inside curly braces
                            return (
                                selectedSubmenu === 'playerPicks' ? (
                                    <Tile
                                        key={index}
                                        data={tileData}
                                        index={index}
                                        expandedIndex={expandedTileIndex}
                                        onExpand={() => setExpandedTileIndex(index)}
                                    />
                                ) : (
                                    <GameTile
                                        key={index}
                                        data={tileData}
                                        index={index}
                                        expandedIndex={expandedTileIndex}
                                        onExpand={() => setExpandedTileIndex(index)}
                                    />
                                )
                            );
                        })}
                    </div>
                </div>
            )}

            {!loading && displayCount < tilesData.length && (
                <button
                    onClick={() => setDisplayCount((prev) => prev + 10)}
                    className="load-more-button"
                >
                    Load More
                </button>
            )}
        </div>
    );
}

export default HomePage;