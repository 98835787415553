import React, { useState } from 'react';
import '../css/FilterMenu.css';

function FilterMenu({
    games,
    selectedGame,
    setSelectedGame,
    selectedDate,
    setSelectedDate,
    selectedSport,
    setSelectedSport,
    onSubmitFilters, // Callback for submitting filters
}) {
    const [minAdvantage, setMinAdvantage] = useState('');
    const [maxAdvantage, setMaxAdvantage] = useState('');
    const [minOdds, setMinOdds] = useState('');
    const [maxOdds, setMaxOdds] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the page from refreshing
        console.log('Submitting filters:', {
            game: selectedGame,
            date: selectedDate,
            sport: selectedSport,
            minAdvantage: parseFloat(minAdvantage) || null, // Convert to float or set to null
            maxAdvantage: parseFloat(maxAdvantage) || null, // Convert to float or set to null
            minOdds: parseFloat(minOdds) || null, // Convert to float or set to null
            maxOdds: parseFloat(maxOdds) || null, // Convert to float or set to null
        });
        if (onSubmitFilters) {
            onSubmitFilters({
                game: selectedGame,
                date: selectedDate,
                sport: selectedSport,
                minAdvantage: parseFloat(minAdvantage) || null,
                maxAdvantage: parseFloat(maxAdvantage) || null,
                minOdds: parseFloat(minOdds) || null,
                maxOdds: parseFloat(maxOdds) || null,
            });
        }
    };

    const handleReset = () => {
        setSelectedGame('');
        setSelectedDate('');
        setSelectedSport('');
        setMinAdvantage('');
        setMaxAdvantage('');
        setMinOdds('');
        setMaxOdds('');
        if (onSubmitFilters) {
            onSubmitFilters({
                game: null,
                date: null,
                sport: null,
                minAdvantage: null,
                maxAdvantage: null,
                minOdds: null,
                maxOdds: null,
            });
        }
    };

    return (
        <div className="filter-menu">
            <div className="filter-options">
                {/* First Row: Game, Date, Sport */}
                <div className="filter-row">
                    <div className="filter-item">
                        <label htmlFor="game-filter">Game:</label>
                        <select
                        id="game-filter"
                        onChange={(e) => setSelectedGame(e.target.value)}
                        value={selectedGame}
                    >
                        <option value="">Select Game</option>
                        {games.map((game) => (
                            <option key={game.GameID} value={`${game.HomeTeamID} vs ${game.AwayTeamID}`}>
                                {`${game.HomeTeamID} vs ${game.AwayTeamID}`}
                            </option>
                        ))}
                    </select>
                    </div>
                    <div className="filter-item">
                        <label htmlFor="date-filter">Date:</label>
                        <input
                            id="date-filter"
                            type="date"
                            value={selectedDate || ''}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        />
                    </div>
                    <div className="filter-item">
                        <label htmlFor="sport-filter">Sport:</label>
                        <select
                            id="sport-filter"
                            onChange={(e) => setSelectedSport(e.target.value)}
                            value={selectedSport}
                        >
                            <option value="">Select Sport</option>
                            <option value="AFL">AFL</option>
                            <option value="NBA">NBA</option>
                        </select>
                    </div>
                </div>

                {/* Second Row: Mooolah Advantage, Odds */}
                <div className="filter-row">
                    <div className="filter-item">
                        <label>Mooolah Advantage:</label>
                        <div className="filter-range">
                            <input
                                type="number"
                                placeholder="Min"
                                value={minAdvantage}
                                onChange={(e) => setMinAdvantage(e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Max"
                                value={maxAdvantage}
                                onChange={(e) => setMaxAdvantage(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="filter-item">
                        <label>Odds:</label>
                        <div className="filter-range">
                            <input
                                type="number"
                                placeholder="Min"
                                value={minOdds}
                                onChange={(e) => setMinOdds(e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Max"
                                value={maxOdds}
                                onChange={(e) => setMaxOdds(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                {/* Submit and Reset Buttons */}
                <div className="filter-submit">
                    <button className="submit-button" onClick={handleSubmit}>
                        Apply Filters
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FilterMenu;      