import '../css/Hero.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';

function Hero() {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/signup');
  };

  return (
    <section id="hero" className="hero">
      <div className="hero-content">
        <h1>MACHINE LEARNING MEETS SPORTS BETTING</h1>
        <p>Our algorithms analyze past sporting events looking for an edge over the bookmakers</p>
        <div className="button-container">
          <button className="hero-button" onClick={handleGetStartedClick}>Get Started</button>
        </div>
      </div>
    </section>
  );
}

export default Hero;