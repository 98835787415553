// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-table-container {
    margin: 20px;
    padding: 20px;
    background-color: #202020;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.results-table th, .results-table td {
    border: 1px solid #e5e5e5;
    padding: 8px;
    text-align: center;
}

.results-table th {
    background-color: #4CAF50;
    color: white;
}

.results-table tr:nth-child(even) {
    background-color: #323232;
}

.results-table tr:hover {
    background-color: #4CAF50;
}`, "",{"version":3,"sources":["webpack://./src/css/ResultsTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".results-table-container {\n    margin: 20px;\n    padding: 20px;\n    background-color: #202020;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.results-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n}\n\n.results-table th, .results-table td {\n    border: 1px solid #e5e5e5;\n    padding: 8px;\n    text-align: center;\n}\n\n.results-table th {\n    background-color: #4CAF50;\n    color: white;\n}\n\n.results-table tr:nth-child(even) {\n    background-color: #323232;\n}\n\n.results-table tr:hover {\n    background-color: #4CAF50;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
