import React from 'react';
import Footer from "../components/Footer";
import '../css/TOS.css';

function TOS() {
  return (
    <div className="tos-page">
      <h2>Terms & Conditions</h2>

      <h3>General</h3>
      <p>
        Anyone accessing our website and other platforms, including any associated services, must be at least 18 years old.
      </p>
      <p>
        By accessing the platforms and using the services offered by Mooolah Pty Ltd (“Mooolah”, “we”, “our”), you agree to
        these terms and conditions and our privacy policy.
      </p>

      <h3>Amendments to the Terms and Conditions</h3>
      <p>
        We may update these terms and conditions and our privacy policy periodically. The updated terms and conditions will
        be published on our website and become effective upon publication. We will notify you of any material amendments.
      </p>
      <p>
        By continuing to use our services after the updated terms and conditions are published, you consent to be bound by them.
      </p>

      <h3>Subscriptions</h3>
      <p>
        We provide various subscription packages. By subscribing, you agree to:
      </p>
      <ul>
        <li>Be at least 18 years old.</li>
        <li>Create an account on our platform.</li>
        <li>Accept these terms and conditions during the subscription process.</li>
        <li>Pay subscription fees in advance as specified in your chosen package.</li>
      </ul>

      <h4>Non-Automatic Renewal Products</h4>
      <p>
        These products begin on the purchase date and end after the specified period. To continue receiving services, you
        must manually renew your subscription.
      </p>

      <h4>Automatic Renewal Products</h4>
      <p>
        For automatic renewal subscriptions:
      </p>
      <ul>
        <li>You authorize us to charge your payment method automatically for each renewal.</li>
        <li>You agree to provide current payment details and update them as necessary.</li>
        <li>You can cancel automatic renewal through your account before the renewal date.</li>
      </ul>

      <h4>Trial Period</h4>
      <p>
        We may offer a trial period for new subscribers. Trials are limited to one per person. At the end of the trial period,
        you will be charged automatically unless you cancel before the trial ends.
      </p>

      <h3>Pricing</h3>
      <p>
        Prices are displayed at the time of purchase and include GST. We may adjust subscription pricing with at least 14 days’
        notice. Price changes apply to new subscriptions or renewals.
      </p>

      <h3>Cancellation</h3>
      <p>
        You may cancel your subscription at any time. Services will remain active until the end of the paid period.
      </p>
      <p>
        We reserve the right to cancel your subscription if you breach these terms and conditions.
      </p>

      <h3>Refunds</h3>
      <p>
        Refunds are not offered for automatic renewal subscriptions. Refunds for non-automatic products are only available if
        requested before the service begins.
      </p>

      <h3>Liability</h3>
      <p>
        Mooolah is not liable for any losses or damages arising from the use of our services. Users are responsible for managing
        their gambling activities responsibly. We do not provide financial advice or guarantees for outcomes based on our
        content.
      </p>

      <h3>Subscriber Conduct</h3>
      <p>
        You agree not to engage in prohibited activities, including illegal conduct, breaches of privacy laws, or actions
        compromising network security.
      </p>

      <h3>Copyright and Intellectual Property</h3>
      <p>
        All content on our platforms is owned or licensed by Mooolah. Your subscription grants you access to the content but
        does not transfer ownership. Reproduction or transmission of content is prohibited without written permission.
      </p>

      <h3>Waiver</h3>
      <p>
        Failure to enforce any provision of these terms does not constitute a waiver of our rights.
      </p>

      <h3>Invalidity and Severability</h3>
      <p>
        If any provision of these terms is deemed invalid or unenforceable, the remaining provisions will remain in effect.
      </p>

      <h3>Jurisdiction and Governing Law</h3>
      <p>
        These terms and your use of our platforms are governed by the laws of New South Wales, Australia. You submit to the
        non-exclusive jurisdiction of its courts.
      </p>
      <div>
        <Footer/>
      </div>  
    </div>
  );
}

export default TOS;