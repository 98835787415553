import React from 'react';

function SuccessPage() {
  return (
    <div>
      <h2>Payment Successful!</h2>
      <p>Your subscription is now active. Thank you for subscribing!</p>
    </div>
  );
}

export default SuccessPage;