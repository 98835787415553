// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Small devices (phones, less than 600px) */
@media (max-width: 600px) {
    body {
        font-size: 14px; /* Smaller base font for readability */
    }
    h1 {
        font-size: 24px; /* Scale down headings */
    }
    p {
        font-size: 16px; /* Scale down paragraph text */
    }
}

@media (max-width: 600px) {
    section {
        padding: 10px; /* Reduce padding for small screens */
    }
}

/* Reset some default styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto' , sans-serif;
    background-color: #121212; /* Dark background */
    color: #e0e0e0; /* Light text for contrast */
}











`, "",{"version":3,"sources":["webpack://./src/css/Global.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;IACI;QACI,eAAe,EAAE,sCAAsC;IAC3D;IACA;QACI,eAAe,EAAE,wBAAwB;IAC7C;IACA;QACI,eAAe,EAAE,8BAA8B;IACnD;AACJ;;AAEA;IACI;QACI,aAAa,EAAE,qCAAqC;IACxD;AACJ;;AAEA,+BAA+B;AAC/B;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kCAAkC;IAClC,yBAAyB,EAAE,oBAAoB;IAC/C,cAAc,EAAE,4BAA4B;AAChD","sourcesContent":["/* Small devices (phones, less than 600px) */\n@media (max-width: 600px) {\n    body {\n        font-size: 14px; /* Smaller base font for readability */\n    }\n    h1 {\n        font-size: 24px; /* Scale down headings */\n    }\n    p {\n        font-size: 16px; /* Scale down paragraph text */\n    }\n}\n\n@media (max-width: 600px) {\n    section {\n        padding: 10px; /* Reduce padding for small screens */\n    }\n}\n\n/* Reset some default styling */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nhtml {\n    scroll-behavior: smooth;\n}\n\nbody {\n    font-family: 'Roboto' , sans-serif;\n    background-color: #121212; /* Dark background */\n    color: #e0e0e0; /* Light text for contrast */\n}\n\n\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
