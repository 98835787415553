import '../css/form-styles.css';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');  // For the username
  const [phone, setPhone] = useState('');  // For the phone number
  const [errorMessage, setErrorMessage] = useState(null);  // For showing error messages
  const navigate = useNavigate();

  // Step 1: Check if the username is unique
  const checkUsernameExists = async (username) => {
    const { data, error } = await supabase.rpc('check_username_exists', { username_input: username });
  
    if (error) {
      console.error('Error checking username:', error.message);
      return true; // Assume username is taken if there's an error
    }
  
    return data; // `data` will be true if username exists, false otherwise
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    // Step 2: Check if the username already exists
    const usernameExists = await checkUsernameExists(username);
    if (usernameExists) {
      setErrorMessage("Username is already taken. Please choose another.");
      return;
    }

    try {
      // Step 3: Proceed with auth sign-up only if username is unique
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        if (error.message.includes("User already registered")) {
          setErrorMessage("Email is already registered. Please use a different email or log in.");
        } else {
          console.error("Error signing up:", error.message);
          setErrorMessage(error.message);
        }
        return;
      }

      // Step 4: Insert the profile with the new user ID, username, and phone
      const { error: profileError } = await supabase
        .from('profiles')
        .insert([
          { user_id: data.user.id, username, phone },
        ]);

      if (profileError) {
        if (profileError.message.includes("duplicate key value violates unique constraint")) {
          setErrorMessage("Username is already taken. Please choose another.");
        } else {
          console.error("Error inserting profile:", profileError.message);
          setErrorMessage(profileError.message);
        }
      } else {
        alert("Sign up successful!");
        navigate('/'); // Redirect to the home page after successful sign-up
      }
    } catch (error) {
      console.error("Unexpected error during sign-up:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="auth-form">
      <h2>Sign Up</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}  {/* Show error messages */}
      <form onSubmit={handleSignup}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="tel"
          placeholder="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
}

export default Signup;