import { ResponsiveLine } from '@nivo/line';
import React, { useEffect, useState } from 'react';

const Graph = ({ playerId, gameDate, benchmarkValue, betStatType}) => {
    console.log("Graph received props:", { playerId, gameDate, benchmarkValue, betStatType});
    console.log('Annotations:', [
        {
            type: 'line',
            axis: 'y',
            value: parseFloat(benchmarkValue),
        },
    ]);

    const [last5Games, setLast5Games] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!playerId || !gameDate) return;
    
        const fetchLast5Games = async () => {
            const apiUrl = `http://localhost:5001/player/last5games?playerId=${playerId}&gameDate=${gameDate}`;
            console.log(`http://localhost:5001/player/last5games?playerId=${playerId}&gameDate=${gameDate}`)
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();
    
                const performanceData = {
                    id: 'Performance',
                    data: result.map((game) => ({
                        x: game.GameDate, // Use GameDate as the X-axis label
                        y: game.Fantasy, //  have to make dynamic
                    })),
                };
    
                const benchmarkData = {
                    id: 'Benchmark',
                    data: result.map((game) => ({
                        x: game.GameDate, // Match the same X-axis labels
                        y: parseFloat(benchmarkValue), // Flat benchmark value across all points
                    })),
                };
    
                setLast5Games({ data: [performanceData, benchmarkData], rawData: result });
            } catch (error) {
                console.error('Error fetching last 5 games:', error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchLast5Games();
    }, [playerId, gameDate, benchmarkValue]);
    
    

    const getYAxisLimits = (data) => {
        const values = data.rawData.map((game) => game.Fantasy);
        const benchmark = parseFloat(benchmarkValue);
        const min = Math.min(...values, benchmark); // Include benchmark value
        const max = Math.max(...values, benchmark); // Include benchmark value
    
        const padding = (max - min) * 0.1;
        return { min: Math.floor(min - padding), max: Math.ceil(max + padding) };
    };

    const formatDateToDDMM = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month
        return `${day}/${month}`; // Format as dd/mm
    };

    return (
        <div style={{ height: '150px', width: '100%', padding: '10px' }}>
            {loading ? (
                <p>Loading graph...</p>
            ) : last5Games ? (
                <ResponsiveLine
                    data={last5Games.data} // Includes both Performance and Benchmark datasets
                    margin={{ top: 20, right: 20, bottom: 30, left: 50 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: getYAxisLimits(last5Games).min,
                        max: getYAxisLimits(last5Games).max,
                        stacked: false,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '', // No Y-axis legend
                        legendPosition: 'middle',
                        legendOffset: -40,
                    }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        format: formatDateToDDMM, // Format X-axis dates as dd/mm
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 40,
                    }}
                    layers={[
                        'grid',
                        'axes',
                        'lines', // Default line rendering
                        'points',
                        'markers',
                        ({ data, xScale, yScale }) => {
                            // Custom benchmark line rendering
                            const benchmarkData = data.find((d) => d.id === 'Benchmark');
                            if (!benchmarkData) return null; // Skip if no benchmark data

                            const linePoints = benchmarkData.data.map((point) => ({
                                x: xScale(point.x),
                                y: yScale(point.y),
                            }));

                            return (
                                <path
                                    d={`
                                        M${linePoints.map((p) => `${p.x},${p.y}`).join(' L')}
                                    `}
                                    fill="none"
                                    // stroke="orange"
                                    strokeWidth={1}

                                />
                            );
                        },
                    ]}
                    colors={({ id }) => (id === 'Performance' ? '#007bff' : 'orange')} // Assign colors by id
                    lineWidth={({ id }) => (id === 'Benchmark' ? 20 : 0)} // Prevent double rendering for Benchmark
                    enablePoints={({ id }) => id !== 'Benchmark'} // Disable points for Benchmark
                    pointSize={({ id }) => (id === 'Benchmark' ? 0 : 10)}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    useMesh={true}
                    enableGridX={false}
                    enableGridY={true}
                    enableCrosshair={false}
                />



            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

export default Graph;
