import React from 'react';
import '../css/ResultsTable.css'; // Optional CSS file for styling

const ResultsTable = ({ resultsData }) => {
    if (!resultsData || resultsData.length === 0) {
        return <p>No data available to display.</p>;
    }

    return (
        <div className="results-table-container">
            <table className="results-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Round</th>
                        <th>Home Team</th>
                        <th>Away Team</th>
                        <th>Player Name</th>
                        <th>Stat Type</th>
                        <th>Stat Line</th>
                        <th>Odds</th>
                        <th>Prediction</th>
                        <th>Win/Loss</th>
                    </tr>
                </thead>
                <tbody>
                    {resultsData.map((result, index) => (
                        <tr key={index}>
                            <td>{result.Date  ||  result.Games?.GameDate}</td> 
                            <td>{result.Round || result.Games?.Round}</td>
                            <td>{result.HomeTeamID || result.Games?.HomeTeamID}</td>
                            <td>{result.AwayTeamID || result.Games?.AwayTeamID}</td>
                            <td>{result.Name}</td>
                            <td>{result.StatType}</td>
                            <td>{result.StatLine}</td>
                            <td>${result.OddsValue}</td>
                            <td>{result.PredictedOdds || result.Model_probability}</td>
                            <td>{result.WinLoss || result.Outcome}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ResultsTable;