import React from 'react';
import '../css/Tile.css'; // Add any new styles specific to GameTile if needed

function GameTile({ data, index, expandedIndex, onExpand }) {
    const isExpanded = expandedIndex === index;

    return (
        <div className={`tile ${isExpanded ? 'expanded' : ''}`} onClick={onExpand}>
            <div className="tile-header">
                <h3>{data.HomeTeam} vs {data.AwayTeam}</h3>
                <p>{data.GameDate}</p>
            </div>
            <div className="tile-content">
                <p><strong>Predicted Margin:</strong> {data.PredictedMargin}</p>
                <p><strong>Odds:</strong> {data.Odds}</p>
                <p><strong>Bet Type:</strong> {data.BetType}</p>
                <p><strong>Bookie:</strong> {data.Bookie}</p>
                <p><strong>Category:</strong> {data.BetCategory}</p>
            </div>
        </div>
    );
}

export default GameTile;