import React from 'react';

function CancelPage() {
  return (
    <div>
      <h2>Payment Canceled</h2>
      <p>Your payment was canceled. You can try again whenever you're ready.</p>
    </div>
  );
}

export default CancelPage;